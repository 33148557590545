import React, { useState } from "react";

import Check from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import { TextField } from "@material-ui/core";
import classes from "./phoneNumbers.module.scss";

const PhoneNumbers = ({
	validatePhoneNumber,
	setPhoneNumbers,
	smsPhoneNumbers,
	edit,
	remove,
}) => {
	const [phoneNumberValidationError, setPhoneNumberValidationError] = useState(
		""
	);
	return smsPhoneNumbers.map(({ phoneNumber, beingEdited }, idx) =>
		beingEdited ? (
			<div className={classes.phoneNumberContainer} key={`phone-number-${idx}`}>
				<TextField
					label="Send to:"
					error={Boolean(phoneNumberValidationError)}
					defaultValue="+254"
					id="edit-phone-number"
					value={phoneNumber}
					onChange={(event) => {
						setPhoneNumbers(
							smsPhoneNumbers.map(({ phoneNumber, beingEdited }) =>
								beingEdited
									? { phoneNumber: event.target.value, beingEdited }
									: { phoneNumber, beingEdited }
							)
						);
						validatePhoneNumber(
							event.target.value,
							() => {
								setPhoneNumberValidationError("");
							},
							(error) => setPhoneNumberValidationError(error.message)
						);
					}}
					helperText={phoneNumberValidationError}
				/>
				<IconButton
					aria-label="save"
					onClick={edit(idx, false)}
					disabled={Boolean(phoneNumberValidationError)}
					data-testid="save-number-icon"
				>
					<Check style={{ cursor: "pointer" }} />
				</IconButton>
			</div>
		) : (
			<div
				key={`phone-number-${idx}`}
				className={classes.phoneNumberContainer}
				data-testid="non-editable-number"
			>
				{phoneNumber}
				<div>
					<EditIcon
						onClick={edit(idx, true)}
						color="secondary"
						style={{ marginRight: "6px", cursor: "pointer" }}
						data-testid="edit-phone-number-icon"
					/>
					<DeleteIcon
						onClick={remove(idx)}
						color="error"
						style={{ marginLeft: "6px", cursor: "pointer" }}
						data-testid="delete-phone-number-icon"
					/>
				</div>
			</div>
		)
	);
};
export default PhoneNumbers;
