import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Facilities from "./Facilities";
import FacilityDetails from "./FacilityDetails/FacilityDetails";
import { useApi } from "../providers/APIProvider";

const GetFacility = ({ match }) => {
	const [facilities, setFacilities] = useState([]);
	const api = useApi();
	const location = useLocation();

	useEffect(() => {
		api.get("/facilities").then(({ data }) => setFacilities(data?.data || []));
	}, [api, location]);

	const pickFacilityData = (id) => {
		return facilities.find((item) => item.facilityId === id);
	};

	if (match && match.params && match.params.facilityId) {
		const facilityData = pickFacilityData(match.params.facilityId);
		if (facilityData) {
			const setFacility = (newFacility) => {
				setFacilities(
					facilities.map((f) =>
						f.facilityId === newFacility.facilityId ? newFacility : f
					)
				);
			};
			return <FacilityDetails data={facilityData} setFacility={setFacility} />;
		}
	}

	return <Facilities data={facilities} />;
};

export default GetFacility;
