import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../../components/layout/dialogTitle/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classes from "./confirmationSmsMessage.module.scss";
import { useSnackbar } from "notistack";
import { useApi } from "../../providers/APIProvider";
import { handleServerError } from "../../utils/utils";

const ConfirmationMessage = ({
	onClose,
	open,
	smsMessage,
	phoneNumbers,
	setSmsText,
}) => {
	const [isEdited, setEdit] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const api = useApi();

	const sendSms = () => {
		return new Promise((resolve, reject) => {
			api
				.post("/facilities/sendBulkSms", {
					smsMessage,
					mobileNumbers: phoneNumbers,
				})
				.then(() => {
					enqueueSnackbar("SMS sent", { variant: "success" });
					resolve();
					onClose();
				})
				.catch((err) => {
					handleServerError(err, reject, enqueueSnackbar);
					onClose();
				});
		});
	};

	return (
		<Dialog
			className={classes.dialogContainer}
			onClose={onClose}
			open={open}
			fullWidth={true}
			maxWidth="xs"
		>
			<DialogTitle title="Please review the sms" onClose={onClose} />

			<DialogContent dividers maxWidth="sm">
				<Typography gutterBottom>
					<p className={classes.title} data-testid="confirmation-title">
            Send to {phoneNumbers.length} recipients
					</p>
				</Typography>
				{isEdited ? (
					<TextField
						multiline
						value={smsMessage}
						id="edit-input-field-sms"
						variant="filled"
						onChange={({ target }) => setSmsText(target.value)}
						className={classes.smsTextField}
					/>
				) : (
					<Typography
						data-testid="sms-text"
						className={classes.smsTextField}
						gutterBottom
					>
						{smsMessage}
					</Typography>
				)}
				<DialogActions className={classes.buttonContainer}>
					<Button
						className={classes.smsButton}
						variant="contained"
						color="primary"
						onClick={() => setEdit(!isEdited)}
						data-testid="save-edit-button"
					>
						{isEdited ? "Save" : "Edit"}
					</Button>

					<Button
						disabled={smsMessage.length === 0 || isEdited}
						variant="contained"
						className={classes.smsButton}
						color="secondary"
						onClick={sendSms}
						data-testid="button-send-mass-sms"
					>
            Send SMS
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};

export default ConfirmationMessage;
