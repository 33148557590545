import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: 0,
		minWidth: 120,
	},
}));

const NativeSelects = ({
	value = "",
	opts = [["select", ""]],
	handleChange = () => {
		console.log("noop");
	},
	helperText = "",
}) => {
	const classes = useStyles();
	const [selectValue, setValue] = useState(value);

	const options = () => {
		return opts.map((opt, i) => {
			return (
				<option key={`opt_${i}_${opt[1]}`} value={opt[1]}>
					{opt[0]}
				</option>
			);
		});
	};

	const handleOnChange = (event) => {
		const v = event.target.value;
		setValue(v);
		handleChange(v);
	};

	return (
		<FormControl className={classes.formControl}>
			<NativeSelect
				value={selectValue}
				onChange={handleOnChange}
				name="age"
				className={classes.selectEmpty}
				inputProps={{ "aria-label": "age" }}
			>
				{options()}
			</NativeSelect>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
};

export default NativeSelects;
