import * as queryString from "query-string";

import React, { useEffect } from "react";

import { useAuth } from "../../providers/AuthProvider";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

const Callback = () => {
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const { location } = history;
	const queryParams = queryString.parse(location.search);
	const { logout, authenticate } = useAuth();

	useEffect(() => {
		if (queryParams?.authorization_token && queryParams?.refresh_token) {
			authenticate(queryParams);
			history.push("/");
		} else {
			logout();
			history.push("/login");
			enqueueSnackbar("Please use the correct credentials", {
				variant: "error",
			});
		}
	}, [queryParams, enqueueSnackbar, history, logout, authenticate]);

	return <div style={{ margin: 10 }}>Loading...</div>;
};

export default Callback;
