import React, { useState } from "react";
import { MTableEditField } from "material-table";
import { fieldValidation } from "../../schema/validation/validateInChargeEditRow";

const InChargeEditField = (props) => {
	const [error, setError] = useState(false);
	const fieldSchema = fieldValidation[props.columnDef.field]();
	const [helperText, setText] = useState(props.columnDef.title);
	const [dirty, setDirty] = useState(false);

	const dofieldValidation = (val) => {
		fieldSchema
			.validate(val)
			.then(() => {
				setError(false);
				setText(props.columnDef.title);
			})
			.catch((err) => {
				setError(true);
				setText(err.message);
			});
	};

	return (
		<MTableEditField
			{...props}
			onChange={(val) => {
				if (dirty) {
					dofieldValidation(val);
				}
				props.onChange(val);
			}}
			error={error}
			helperText={helperText}
			onBlur={(e) => {
				let val = e.target.value;
				dofieldValidation(val);
				setDirty(true);
			}}
		/>
	);
};

export default InChargeEditField;
