import React from "react";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	gutters: {
		paddingLeft: 30,
		paddingRight: 30,
	},
}));

const MenuItem = ({ to, renderIcon, text, dataTestId = "" }) => {
	const materialClasses = useStyles();

	return (
		<ListItem
			component={Link}
			to={to}
			button
			classes={{
				gutters: materialClasses.gutters,
			}}
			data-testid={dataTestId}
		>
			<ListItemIcon>{renderIcon()}</ListItemIcon>
			<ListItemText primary={text} />
		</ListItem>
	);
};

export default MenuItem;
