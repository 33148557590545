import Drawer from "@material-ui/core/Drawer";
import HomeIcon from "@material-ui/icons/Home";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import { Logout } from "../../assets/icons/Logout";
import MenuItem from "./MenuItem";
import React from "react";
import SmsIcon from "@material-ui/icons/Sms";
import classes from "./drawer.module.scss";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: drawerWidth,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		marginTop: 48 + 10,
		paddingBottom: 15,
	},
	list: {
		color: "rgba(0, 0, 0, 0.87)",
		textDecoration: "none",
	},
}));

const CustomDrawer = ({ open, onClose }) => {
	const materialClasses = useStyles();
	return (
		<Drawer
			anchor="left"
			variant="temporary"
			open={open}
			onClose={onClose}
			className={materialClasses.drawer}
			classes={{
				paper: materialClasses.drawerPaper,
			}}
		>
			<div className={clsx(classes.container, materialClasses.drawerContainer)}>
				<List className={materialClasses.list}>
					<MenuItem
						to="/"
						renderIcon={() => <HomeIcon color="secondary" />}
						text="Facilities"
					/>
					<MenuItem
						to="/sendSms"
						renderIcon={() => <SmsIcon color="secondary" />}
						text="Send SMS"
						dataTestId="sms-navbar-button"
					/>
				</List>
				<List component={Link} to="/logout" className={materialClasses.list}>
					<MenuItem
						to="/logout"
						renderIcon={() => (
							<Logout className={classes.logoutIcon} color="secondary" />
						)}
						text="Logout"
					/>
				</List>
			</div>
		</Drawer>
	);
};

export default CustomDrawer;
