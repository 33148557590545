import { BrowserRouter, Route, Switch } from "react-router-dom";

import APIProvider from "./providers/APIProvider";
import AuthProvider from "./providers/AuthProvider";
import Callback from "./pages/auth/Callback";
import GetFacility from "./pages/GetFacility";
import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import PrivateRoute from "./components/route/PrivateRoute";
import React from "react";
import { SendSms } from "./pages/sms/SendSms";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/core/styles";
import muiTheme from "./style/materialUi";

const App = () => {
	return (
		<BrowserRouter>
			<ThemeProvider theme={muiTheme}>
				<SnackbarProvider maxSnack={3}>
					<AuthProvider>
						<APIProvider>
							<Switch>
								<PrivateRoute exact path="/" component={GetFacility} />
								<PrivateRoute
									path={"/facility/:facilityId"}
									component={GetFacility}
								/>
								<PrivateRoute path={"/sendSms"} component={SendSms} />
								<Route exact path="/login" component={Login} />
								<Route exact path="/logout" component={Logout} />
								<Route exact path="/callback" component={Callback} />
							</Switch>
						</APIProvider>
					</AuthProvider>
				</SnackbarProvider>
			</ThemeProvider>
		</BrowserRouter>
	);
};

export default App;
