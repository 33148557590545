import React, { createContext, useContext, useMemo, useState } from "react";

export const AuthContext = createContext();

const SESSION_AUTHORIZATION_TOKEN_KEY = "authorizationToken";
const SESSION_REFRESH_TOKEN_KEY = "refreshToken";

const getSession = () => {
	const authorizationToken = sessionStorage.getItem(
		SESSION_AUTHORIZATION_TOKEN_KEY
	);
	const refreshToken = sessionStorage.getItem(SESSION_REFRESH_TOKEN_KEY);
	if (authorizationToken && refreshToken) {
		return {
			authorizationToken,
			refreshToken,
			isAuthenticated: true,
		};
	}
	return null;
};

const setSession = ({ authorization_token, refresh_token }) => {
	sessionStorage.setItem(SESSION_AUTHORIZATION_TOKEN_KEY, authorization_token);
	sessionStorage.setItem(SESSION_REFRESH_TOKEN_KEY, refresh_token);
};

const removeSession = () => {
	sessionStorage.removeItem(SESSION_AUTHORIZATION_TOKEN_KEY);
	sessionStorage.removeItem(SESSION_REFRESH_TOKEN_KEY);
};

const AuthProvider = ({ children }) => {
	const [authState, setAuthState] = useState({
		isAuthenticated: false,
		...getSession(),
	});

	const authenticate = (
		{ authorization_token, refresh_token },
		saveSession = true
	) => {
		if (saveSession) {
			setSession({ authorization_token, refresh_token });
		}
		setAuthState({
			isAuthenticated: true,
			authorizationToken: authorization_token,
			refreshToken: refresh_token,
		});
	};

	const logout = () => {
		setAuthState({
			isAuthenticated: false,
			accessToken: undefined,
			refreshToken: undefined,
		});
		removeSession();
	};

	const decodedPayload = useMemo(() => {
		try {
			const { isAuthenticated, authorizationToken } = authState;
			if (isAuthenticated) {
				const [, rawPayload] = authorizationToken.split(".");
				return JSON.parse(Buffer.from(rawPayload, "base64").toString("utf8"));
			}
		} catch (payloadMalformedError) {
			console.error(payloadMalformedError);
		}
	}, [authState]);

	return (
		<AuthContext.Provider
			value={{ ...authState, userData: decodedPayload, authenticate, logout }}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
export const AuthWrapper = AuthContext.Consumer;

export const useAuth = () => {
	return useContext(AuthContext);
};
