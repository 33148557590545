import {
	FIELD_INCHARGE_FULLNAME,
	FIELD_INCHARGE_MOBILE_NUMBER,
} from "../../schema/constants";
import React, { forwardRef } from "react";
import { headerStyle, rowStyle } from "./tableStyles";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import InChargeEditField from "./inChargeEditField";
import InChargeEditRow from "./inChargeEditRow";
import LastPage from "@material-ui/icons/LastPage";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SendSmsButton from "../buttons/SendSmsButton";
import ViewColumn from "@material-ui/icons/ViewColumn";

const INCHARGE_TABLE_ICONS = {
	Add: forwardRef((props, ref) => (
		<AddBox {...props} ref={ref} data-testid="add-incharge-button" />
	)),
	Check: forwardRef((props, ref) => (
		<Check {...props} ref={ref} data-testid="save-incharge-button" />
	)),
	Clear: forwardRef((props, ref) => (
		<Clear {...props} ref={ref} data-testid="cancel-save-incharge" />
	)),
	Delete: forwardRef((props, ref) => (
		<DeleteOutline {...props} ref={ref} data-testid="delete-incharge-button" />
	)),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => (
		<Edit {...props} ref={ref} data-testid="edit-incharge-button" />
	)),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const InChargeTable = ({
	data,
	title,
	inChargeAdd,
	inChargeUpdate,
	inChargeDelete,
	resendPin,
	resendDailyReportLink,
	resendProfileLink,
	handleRowValidation,
	isLoading,
	pin,
}) => {
	const INCHARGE_TABLE_COMPONENTS = {
		Container: (props) => <Paper {...props} elevation={0} />,
		EditRow: (props) => (
			<InChargeEditRow {...props} handleRowValidation={handleRowValidation} />
		),
		EditField: (props) => <InChargeEditField {...props} />,
	};

	const INCHARGE_TABLE_OPTIONS = {
		headerStyle,
		rowStyle,
		showTitle: false,
		actionsColumnIndex: -1,
		emptyRowsWhenPaging: false,
		paging: false,
	};

	const INCHARGE_TABLE_COLUMNS = [
		{ title: "In-charge name", field: FIELD_INCHARGE_FULLNAME },
		{
			title: "Mobile Number",
			field: FIELD_INCHARGE_MOBILE_NUMBER,
			initialEditValue: "+254",
		},
		{
			title: "Facility Profile",
			render: (rowData) => (
				<SendSmsButton
					sendSms={resendProfileLink}
					rowData={rowData}
					textToDisplay="Send SMS"
				/>
			),
		},
		{
			title: "PIN",
			render: (rowData) => (
				<SendSmsButton
					sendSms={resendPin}
					rowData={rowData}
					isPin={true}
					pin={pin}
					textToDisplay="Send Pin"
				/>
			),
		},
		{
			title: "Daily Report",
			render: (rowData) => (
				<SendSmsButton
					sendSms={resendDailyReportLink}
					rowData={rowData}
					textToDisplay="Send Link"
				/>
			),
		},
	];

	return (
		<MaterialTable
			icons={INCHARGE_TABLE_ICONS}
			columns={INCHARGE_TABLE_COLUMNS}
			data={data}
			title={title}
			options={INCHARGE_TABLE_OPTIONS}
			components={INCHARGE_TABLE_COMPONENTS}
			editable={{
				isEditable: () => true,
				isDeletable: () => true,
				onRowAdd: inChargeAdd,
				onRowUpdate: inChargeUpdate,
				onRowDelete: inChargeDelete,
			}}
			isLoading={isLoading}
		/>
	);
};

InChargeTable.defaultProps = {
	title: "In-charge details",
	data: {},
	inChargeAdd: () => {
		alert("ADD IN CHARGE default function}");
	},
	inChargeUpdate: (facilityId, phoneNumber) => {
		alert("UPDATE IN CHARGE default function}");
	},
	inChargeDelete: (facilityId, phoneNumber) => {
		alert("UPDATE IN CHARGE default function}");
	},
	resendPin: (facilityId, phoneNumber) => {
		alert(`FacilityId ${facilityId}, phone: ${phoneNumber}`);
	},
	resendDailyReportLink: (facilityId, phoneNumber) => {
		alert(`FacilityId ${facilityId}, phone: ${phoneNumber}`);
	},
	resendProfileLink: (facilityId, phoneNumber) => {
		alert(`FacilityId ${facilityId}, phone: ${phoneNumber}`);
	},
};

InChargeTable.propTypes = {
	title: PropTypes.string.isRequired,
	data: PropTypes.array,
	inChargeAdd: PropTypes.func.isRequired,
	inChargeUpdate: PropTypes.func.isRequired,
	inChargeDelete: PropTypes.func.isRequired,
	resendPin: PropTypes.func.isRequired,
	resendDailyReportLink: PropTypes.func.isRequired,
	resendProfileLink: PropTypes.func.isRequired,
};

export default InChargeTable;
