import FacilitiesTable from "../components/Table/facilityList";
import Page from "../components/layout/page/Page";
import React from "react";
import Title from "../components/layout/title/Title";

const Facilities = ({ data }) => {
	return (
		<Page crumbs={[{ path: "/", title: "Facilities" }]}>
			<Title title="All Facilities" />
			<FacilitiesTable data={data} />
		</Page>
	);
};

export default Facilities;
