export const intersperse = (arr, sep) =>
	arr.reduce((a, v) => [...a, v, sep], []).slice(0, -1);

export const handleServerError = (err, reject, enqueueSnackbar) => {
	if (err?.response?.data?.errors) {
		err.response.data.errors.forEach((error) => {
			enqueueSnackbar(error.message, { variant: "error" });
		});
	} else {
		enqueueSnackbar("Unexpected error occured", { variant: "error" });
	}
	if (reject) {
		reject();
	}
};
