import Chip from "@material-ui/core/Chip";
import InChargeTable from "../../components/Table/inChargeTable";
import Page from "../../components/layout/page/Page";
import React, { useState } from "react";
import classes from "./facilitydetails.module.scss";
import { useApi } from "../../providers/APIProvider";
import { useSnackbar } from "notistack";
import { handleServerError } from "../../utils/utils";
import Select from "../../components/Input/select";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import DeleteFacilityModal from "../deleteFacilityModal/DeleteFacilityModal";

const cleanList = (list) => {
	// because Material Table adds stuff
	return list.map(({ name, mobileNumber }) => {
		return {
			name,
			mobileNumber,
		};
	});
};

const FacilityDetails = ({ data, setFacility }) => {
	const crumbs = [
		{
			path: "/",
			title: "Facilities",
		},
		{
			path: `/facility/${data.facilityId}`,
			title: data.facilityName,
		},
	];
	const [isDialogOpened, setDialogOpen] = useState(false);

	const deleteButtonStyle = {
		color: "white",
		backgroundColor: "#D23201",
	};
	
	const handleDeleteFacility = () => {
		setDialogOpen(true);
	};
	

	const api = useApi();

	const { enqueueSnackbar } = useSnackbar();

	const setFacilityUpdateAPI = (list) => {
		let req = {
			facilityId: data.facilityId,
			inChargeMembers: cleanList(list),
		};

		return api.put(`/facilities/${req.facilityId}`, req);
	};

	const updateFacilityTypeApi = (req) => {
		return api.put(`/facilities/${req.facilityId}`, req);
	};

	const handleSuccessfulInChargeUpdate = (json, newInChargeMembers) => {
		setFacility({ ...data, inChargeMembers: newInChargeMembers });
		enqueueSnackbar("Successfully added", { variant: "success" });
		return true;
	};

	const inChargeAdd = (newData) => {
		return new Promise((resolve, reject) => {
			let newInChargeMembers = [...data.inChargeMembers];
			newInChargeMembers.push(newData);
			if (newData._validationErrors) {
				// DON'T DO ANYTHING THAT TRIGGERS A REDRAW HERE!
				reject();
			} else {
				setFacilityUpdateAPI(newInChargeMembers)
					.then((json) => {
						handleSuccessfulInChargeUpdate(json, newInChargeMembers);
					})
					.then(resolve)
					.catch((err) => {
						handleServerError(err, reject, enqueueSnackbar);
					});
			}
		});
	};

	const inChargeUpdate = (newData, oldData) => {
		return new Promise((resolve, reject) => {
			let newInChargeMembers = data.inChargeMembers.map((m) =>
				m === oldData ? newData : m
			);
			if (newData._validationErrors) {
				// DON'T DO ANYTHING THAT TRIGGERS A REDRAW HERE!
				reject();
			} else {
				setFacilityUpdateAPI(newInChargeMembers)
					.then((json) => {
						handleSuccessfulInChargeUpdate(json, newInChargeMembers);
					})
					.then(resolve)
					.catch((err) => {
						handleServerError(err, reject, enqueueSnackbar);
					});
			}
		});
	};

	const inChargeDelete = (oldData) => {
		return new Promise((resolve, reject) => {
			let newInChargeMembers = data.inChargeMembers.filter(
				(m) => m !== oldData
			);
			setFacilityUpdateAPI(newInChargeMembers)
				.then((json) => {
					handleSuccessfulInChargeUpdate(json, newInChargeMembers);
				})
				.then(resolve)
				.catch((err) => {
					handleServerError(err, reject, enqueueSnackbar);
				});
		});
	};

	const setSendSmsApi = (mobileNumber, facilityId, typeOfSms) => {
		return api.post(
			`/facilities/${facilityId}/inChargeMembers/${mobileNumber}/${typeOfSms}`
		);
	};

	const sendSms = (mobileNumber, facilityId, typeOfSms, onStart, onFailure) => {
		onStart();
		return new Promise((resolve, reject) => {
			setSendSmsApi(mobileNumber, facilityId, typeOfSms)
				.then(() => {
					enqueueSnackbar("SMS sent", { variant: "success" });
					resolve();
				})
				.catch((err) => {
					enqueueSnackbar("There was an error when trying to send the sms", {
						variant: "error",
					});
					onFailure();
					console.error(err);
					reject();
				});
		});
	};

	const resendPin = (rowData, onStart, onFailure) => {
		if (window.confirm(`Resend PIN to ${rowData.name}?`)) {
			sendSms(rowData.mobileNumber, data.facilityId, "pin", onStart, onFailure);
		}
	};

	const resendDailyReportLink = (rowData, onStart, onFailure) => {
		if (window.confirm(`Resend Daily report link to ${rowData.name}?`)) {
			sendSms(
				rowData.mobileNumber,
				data.facilityId,
				"dailyReportSms",
				onStart,
				onFailure
			);
		}
	};

	const resendProfileLink = (rowData, onStart, onFailure) => {
		if (window.confirm(`Resend Profile link to ${rowData.name}?`)) {
			sendSms(
				rowData.mobileNumber,
				data.facilityId,
				"registrationSms",
				onStart,
				onFailure
			);
		}
	};

	const chipStyles = {
		color: "white",
		fontWeight: "bold",
		height: "22px",
		borderRadius: "14px",
	};

	let chipLabel = "Not registered";
	if (data.dateRegistered) {
		chipLabel = new Date(data.dateRegistered).toLocaleDateString();
	}

	const facilityTypeHandleChange = (selected) => {
		const payload = {
			facilityId: data.facilityId,
			facilityType: selected,
		};
		updateFacilityTypeApi(payload)
			.then((json) => {
				setFacility({ ...data, facilityType: selected });
				enqueueSnackbar("Successfully updated Facility type", {
					variant: "success",
				});
			})
			.catch((err) => {
				handleServerError(err, () => false, enqueueSnackbar);
				setFacility({ ...data });
			});
	};

	return (
		<Page crumbs={crumbs}>
			<div className={classes.facilitySummary}>
				<h1>{data.facilityName}</h1>
				<div className={classes.facilityStatus}>
					<Chip
						label={chipLabel}
						size="small"
						style={
							data.dateRegistered
								? { ...chipStyles, backgroundColor: "#459EAD" }
								: { ...chipStyles, backgroundColor: "#D23201" }
						}
					/>
				</div>
				<div className={classes.inputFieldContainer}>
					<Button
						variant="contained"
						className={classes.deleteFacilityButton}
						startIcon={<DeleteIcon />}
						style={deleteButtonStyle}
						onClick={handleDeleteFacility}
						data-testid="delete-facility-button"
					>
            Delete Facility
					</Button>
				</div>
			</div>
			<div>
				<span className={classes.facilityLocation}>
					{data.organisationName}, {data.subDivision1Name}
				</span>
				<Select
					handleChange={facilityTypeHandleChange}
					value={data.facilityType}
					opts={[
						["Health Facility", "facility"],
						["Quarantine Unit", "quarantine"],
						["Isolation Center", "isolation"],
						["Community Health Unit", "community"],
					]}
					style={{ background: "blue" }}
				/>
			</div>
			<DeleteFacilityModal
				onClose={() => setDialogOpen(false)}
				aria-labelledby="send-confirmation-popup"
				open={isDialogOpened}
				facility={data}
			/>

			<InChargeTable
				data={data.inChargeMembers}
				inChargeAdd={inChargeAdd}
				inChargeUpdate={inChargeUpdate}
				inChargeDelete={inChargeDelete}
				resendPin={resendPin}
				resendDailyReportLink={resendDailyReportLink}
				resendProfileLink={resendProfileLink}
				pin={data.PIN}
				// isLoading={isLoading}
			/>
		</Page>
	);
};

export default FacilityDetails;
