import { ERROR_MESSAGE_REQUIRED } from "./constants";
import { string } from "yup";

export const kenyanPhoneRegex = /^(?:254|\+254|0)?(7[0-9]{8})$/;
export const validateKenyanNumber = string()
	.matches(
		kenyanPhoneRegex,
		"Please enter a valid mobile number e.g. +254789012345"
	)
	.required(ERROR_MESSAGE_REQUIRED);
