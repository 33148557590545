import React from "react";
import clsx from "clsx";
import classes from "./page.module.scss";
import { Logo } from "../../assets/Logo";

const Page = ({ children }) => {
	return (
		<div className={clsx(classes.pageContainer, classes.unauthenticated)}>
			<div className={classes.header}>
				<div className={classes.content}>
					<Logo className={classes.logo} />
					<div className={classes.mottoContainer}>
						<span className={classes.motto}>
              better healthcare for everyone
						</span>
					</div>
				</div>
			</div>
			<div className={classes.pageWrapper}>
				<main className={classes.page}>{children}</main>
			</div>
		</div>
	);
};

export default Page;
