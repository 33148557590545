import React from "react";
import PropTypes from "prop-types";
import MaterialTable from "material-table";

import {
	FACILITIES_TABLE_COLUMNS,
	FACILITIES_TABLE_ICONS,
	FACILITIES_TABLE_OPTIONS,
	FACILITIES_TABLE_COMPONENTS,
} from "./facilityListTableConfig";

const FacilitiesTable = ({ columns, data, actions }) => {
	return (
		<MaterialTable
			icons={FACILITIES_TABLE_ICONS}
			columns={FACILITIES_TABLE_COLUMNS}
			data={data}
			title="Facilities"
			options={FACILITIES_TABLE_OPTIONS}
			components={FACILITIES_TABLE_COMPONENTS}
		/>
	);
};

FacilitiesTable.defaultProps = {
	title: "Facilities",
	data: [],
	columns: FACILITIES_TABLE_COLUMNS,
};

FacilitiesTable.propTypes = {
	title: PropTypes.string.isRequired,
	data: PropTypes.array,
};

export default FacilitiesTable;
