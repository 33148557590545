import React from "react";
import { MTableEditRow } from "material-table";
import { generateSchema } from "../../schema/validation/validateInChargeEditRow";

const schema = generateSchema();

const InChargeEditRow = (props) => {
	const handleEditApproval = (mode, newData, oldData) => {
		if (mode === "delete") {
			// no need to validate here
			props.onEditingApproved(mode, newData, oldData);
			return true;
		}

		schema
			.validate(newData)
			.then(() => {
				props.onEditingApproved(mode, newData, oldData);
			})
			.catch((err) => {
				// approved?  NO! approved, but with a flag to force the 'reject' in the promise
				props.onEditingApproved(
					mode,
					{ ...newData, _validationErrors: err },
					oldData
				);
			});
	};

	return (
		<MTableEditRow
			{...props}
			onEditingApproved={handleEditApproval}
			error={true}
		/>
	);
};

export default InChargeEditRow;
