import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "../../components/layout/dialogTitle/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import classes from "./deletionFacilityModal.module.scss";
import { useHistory } from "react-router-dom";

import { useSnackbar } from "notistack";
import { useApi } from "../../providers/APIProvider";
import { handleServerError } from "../../utils/utils";

const DeleteFacilityModal = ({ onClose, open, facility }) => {
	const [isFacilityDeletable, setIsFacilityDeletable] = useState(false);
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const api = useApi();
	const datePutInTrash = new Date(Date.now());

	const isDeletable = (value) => {
		if (value.match("DELETE")) {
			setIsFacilityDeletable(true);
		}
	};

	const setDeleteFacilityApi = () =>
		api.put(`/facilities/${facility.facilityId}`, {
			facilityId: facility.facilityId,
			datePutInTrash,
		});

	const deleteFacility = () => {
		return new Promise((resolve, reject) => {
			setDeleteFacilityApi()
				.then(() => {
					enqueueSnackbar("Facility successfully deleted", {
						variant: "success",
					});
					resolve();
		  onClose();
					history.push("/");
				})
				.catch((err) => {
					handleServerError(err, reject, enqueueSnackbar);
					onClose();
				});
		});
	};

	return (
		<Dialog
			className={classes.dialogContainer}
			onClose={onClose}
			open={open}
			fullWidth={true}
			maxWidth="xs"
		>
			<DialogTitle
				title="Are you sure you want to delete this clinic?"
				warningMessage={`All ${facility.inChargeMembers.length} in-charges will be deleted`}
			/>

			<DialogContent dividers maxWidth="sm">
				<Typography gutterBottom>
					<h4 className={classes.title} data-testid="confirmation-title">
            Type 'DELETE' in order to delete the facility
					</h4>
				</Typography>
				<div className={classes.deleteInput}>
					<TextField
						multiline
						id="edit-input-field-deletion"
						variant="filled"
						onChange={({ target }) => isDeletable(target.value)}
						className={classes.deleteField}
					/>
				</div>

				<DialogActions className={classes.buttonContainer}>
					<Button
						onClick={onClose}
						data-testid="cancel-deletion"
						color="primary"
					>
            Cancel
					</Button>

					<Button
						disabled={!isFacilityDeletable}
						variant="contained"
						className={classes.deletionButton}
						color="error"
						onClick={deleteFacility}
						data-testid="delete-facility-button"
					>
            Delete
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};

export default DeleteFacilityModal;
