import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
		backgroundColor: "lightGrey"
	},
	closeButton: {
		position: "absolute",
		right: 1,
		top: 1,
		color: "grey",
	},
	typoGraphy: {
		fontWeight: "bold",
		fontSize: "15px",
		textAlign: "center",
	},
	warning: {
		color: "#D23201",
		fontSize: "12px",
		textAlign: "center",
		marginTop: 5,
		fontWeight: "bold",
	}
});

const DialogTitle = withStyles(styles)((props) => {
	const { title, warningMessage, children, classes, onClose, ...other } = props;

	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography className={classes.typoGraphy}>{title}</Typography>
			<Typography className={classes.warning}>{warningMessage}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

export default DialogTitle;
