import { FIELD_SMS_PHONE_NUMBER } from "../constants";
import { object } from "yup";
import { validateKenyanNumber } from "../utils";

const generateSchema = () => {
	return object().shape({
		[FIELD_SMS_PHONE_NUMBER]: validateKenyanNumber,
	});
};

export const fieldValidation = {
	[FIELD_SMS_PHONE_NUMBER]: () => validateKenyanNumber,
};

export const schema = generateSchema();
