import AppBar from "@material-ui/core/AppBar";
import Drawer from "../drawer/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { Logo } from "../../assets/Logo";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import classes from "./page.module.scss";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../../providers/AuthProvider";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	appBar: {
		zIndex: theme.zIndex.modal + 1,
	},
	unauthenticatedAppBar: {
		backgroundColor: "#efefef",
		boxShadow: "none",
	},
	content: {
		marginTop: 48,
	},
	toolbar: {
		minHeight: 48,
	},
}));

const AuthenticatedPage = ({
	children,
	drawerOpen,
	closeDrawer,
	toggleDrawer,
}) => {
	const { userData } = useAuth();
	const materialClasses = useStyles();

	return (
		<div
			className={clsx(
				materialClasses.root,
				classes.pageContainer,
				classes.authenticated
			)}
		>
			<AppBar
				position="fixed"
				className={clsx(
					classes.header,
					materialClasses.appBar,
					materialClasses.unauthenticatedAppBar
				)}
			>
				<Toolbar classes={{ regular: materialClasses.toolbar }}>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={toggleDrawer}
						edge="start"
						className={clsx(classes.menuButton, drawerOpen && classes.hide)}
						data-testid="responsive-burger-button"
					>
						<MenuIcon />
					</IconButton>
					<Logo className={classes.logo} />
					<div className={classes.mottoContainer}>
						<span className={classes.motto}>
              better healthcare for everyone
						</span>
					</div>
					<div className={classes.username}>{userData?._raw?.name}</div>
				</Toolbar>
			</AppBar>
			<Drawer open={drawerOpen} onClose={closeDrawer} />
			<div className={classes.pageWrapper}>
				<main className={clsx(classes.page, materialClasses.content)}>
					{children}
				</main>
			</div>
		</div>
	);
};

export default AuthenticatedPage;
