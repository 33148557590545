import React, { useState } from "react";

import AuthenticatedPage from "./AuthenticatedPage";
import BreadCrumbs from "../breadcrumbs/Breadcrumbs";
import UnauthenticatedPage from "./UnauthenticatedPage";
import { array } from "prop-types";
import classes from "./page.module.scss";
import { useAuth } from "../../../providers/AuthProvider";

const Page = ({ children, crumbs }) => {
	const { isAuthenticated } = useAuth();

	const [drawerOpen, setDrawerOpen] = useState(false);
	const closeDrawer = () => setDrawerOpen(false);
	const toggleDrawer = () => setDrawerOpen(!drawerOpen);

	if (isAuthenticated) {
		return (
			<AuthenticatedPage
				drawerOpen={drawerOpen}
				closeDrawer={closeDrawer}
				toggleDrawer={toggleDrawer}
			>
				<div className={classes.pageContent}>
					{crumbs && <BreadCrumbs crumbs={crumbs} />}
					{children}
				</div>
			</AuthenticatedPage>
		);
	}
	return (
		<UnauthenticatedPage>
			<div className={classes.pageContent}>
				{crumbs && <BreadCrumbs crumbs={crumbs} />}
				{children}
			</div>
		</UnauthenticatedPage>
	);
};

Page.propTypes = {
	crumbs: BreadCrumbs.propTypes.crumbs,
	children: array,
};

export default Page;
