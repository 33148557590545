import React from "react";
import { Route } from "react-router-dom";
import Unauthorized from "../../pages/Unauthorized";
import { useAuth } from "../../providers/AuthProvider";

const PrivateRoute = ({ children, route, ...rest }) => {
	const { isAuthenticated } = useAuth();
	if (isAuthenticated) {
		return (
			<Route route={route} {...rest}>
				{children}
			</Route>
		);
	} else {
		return <Unauthorized />;
	}
};

export default PrivateRoute;
