import React from "react";
import { ReactComponent as GoogleLogo } from "../../assets/googleLogin.svg";
import classes from "./googleButton.module.scss";

const GoogleButton = ({ onClick }) => {
	return (
		<button onClick={onClick} className={classes.button}>
			<div className={classes.iconContainer}>
				<GoogleLogo />
			</div>
			<span className={classes.content}>Sign in with Google</span>
		</button>
	);
};

export default GoogleButton;
