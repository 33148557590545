import React, { useState } from "react";

import Button from "@material-ui/core/Button";

const SendSmsButton = ({ sendSms, rowData, isPin, pin, textToDisplay }) => {
	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const isDisabled = isPin ? !pin || isButtonDisabled : isButtonDisabled;
	return (
		<Button
			disabled={isDisabled}
			disableElevation
			color="secondary"
			onClick={() => {
				sendSms(
					rowData,
					() => setButtonDisabled(true),
					() => setButtonDisabled(false)
				);
			}}
		>
			{textToDisplay}
		</Button>
	);
};

export default SendSmsButton;
