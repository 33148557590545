import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";

import axios from "axios";
import { useAuth } from "./AuthProvider";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

const APIContext = createContext();

const apiInstance = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
});

const isValidRefreshResponse = (data) =>
	data && data.authorization_token && data.refresh_token;

const APIProvider = ({ children }) => {
	const {
		isAuthenticated,
		authorizationToken,
		refreshToken,
		authenticate,
		logout,
	} = useAuth();
	const { push } = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const [hasHeader, setHasHeader] = useState(false);
	const [hasErrorInterceptor, setHasErrorInterceptor] = useState(false);

	const shouldRenderChildren =
    (hasHeader && hasErrorInterceptor && isAuthenticated) || !isAuthenticated;

	const updateToken = useCallback(() => {
		return apiInstance
			.get(`/auth/refresh/${refreshToken}`, {
				retry: false,
			})
			.then(({ data }) => {
				if (isValidRefreshResponse(data)) {
					authenticate(data);
					return data;
				}
				return null;
			});
	}, [authenticate, refreshToken]);

	const replaceAuthHeader = (originalConfig, newAuthToken) => ({
		...originalConfig,
		headers: {
			...originalConfig.headers,
			Authorization: newAuthToken,
		},
	});

	const responseErrorInterceptor = useCallback(
		(error) => {
			if (isAuthenticated) {
				const { config, response } = error;
				if (config && config.retry === false) {
					enqueueSnackbar("Your login details are incorrect or have expired", {
						variant: "error",
					});
					logout();
					push("/login");
					return error;
				}
				if (config && response) {
					const { status: statusCode } = response;
					if (statusCode === 401) {
						return updateToken().then((data) => {
							if (data) {
								const reformedRequest = replaceAuthHeader(
									config,
									data.authorization_token
								);
								return apiInstance.request({
									...reformedRequest,
									retry: false,
								});
							}
							return Promise.reject(new Error("Unable to refresh token"));
						});
					}
				}
				return Promise.reject(error);
			}
		},
		[updateToken, isAuthenticated, enqueueSnackbar, logout, push]
	);

	useEffect(() => {
		const interceptor = apiInstance.interceptors.response.use(
			null,
			responseErrorInterceptor
		);
		setHasErrorInterceptor(true);
		return () => apiInstance.interceptors.response.eject(interceptor);
	}, [responseErrorInterceptor]);

	useEffect(() => {
		if (isAuthenticated) {
			apiInstance.defaults.headers.common.Authorization = authorizationToken;
			setHasHeader(true);
		} else {
			delete apiInstance.defaults.headers.common.Authorization;
			setHasHeader(false);
		}
	}, [isAuthenticated, authorizationToken]);

	return (
		<APIContext.Provider value={{ api: apiInstance }}>
			{shouldRenderChildren ? children : null}
		</APIContext.Provider>
	);
};

export const useApi = () => {
	const { api } = useContext(APIContext);
	return api;
};

export default APIProvider;
