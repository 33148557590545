import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Unauthorized = () => {
	const history = useHistory();
	useEffect(() => {
		history.push("/login");
	}, [history]);

	return <div>Unauthorized</div>;
};

export default Unauthorized;
