import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
	typography: {
		fontFamily: "'Noto Sans', Arial, Helvetica, sans-serif",

		button: {
			textTransform: "none",
		},
	},
	overrides: {
		MuiTablePagination: {
			root: {
				borderBottom: 0,
			},
		},
	},
	palette: {
		primary: {
			main: "#469EAD",
		},
		error: {
			main: "#D23201",
		},
		secondary: {
			main: "#004F71",
		},
		accept: {
			main: "#46A049",
		},
	},
});
