import React, { useState } from "react";

import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import ConfirmationMessage from "../confimationSmsMessage/ConfirmationSmsMessage";
import { FIELD_SMS_PHONE_NUMBER } from "../../schema/constants";
import Page from "../../components/layout/page/Page";
import PhoneNumbers from "./PhoneNumbers";
import TextField from "@material-ui/core/TextField";
import Title from "../../components/layout/title/Title";
import _ from "lodash";
import classes from "./sendSms.module.scss";
import { kenyanPhoneRegex } from "../../schema/utils";
import { schema } from "../../schema/validation/validateSmsPage";

export const removeInvalidPhoneNumbers = (phoneNumbers) =>
	_(phoneNumbers)
		.map((p) => p.phoneNumber)
		.uniq()
		.filter((p) => p.match(kenyanPhoneRegex))
		.value();

export const SendSms = () => {
	const [phoneNumbers, setPhoneNumbers] = useState([]);
	const [phoneNumber, setPhoneNumber] = useState("+254");
	const [smsMessage, setSmsText] = useState("");
	const [smsValidationError, setSmsValidationError] = useState("");
	const [isDialogOpened, setDialogOpen] = useState(false);
	const [validPhoneNumbers, setValidPhoneNumbers] = useState([]);
	const validPhoneNumberLength = 13;

	const count = Math.ceil(smsMessage.length / 142);

	const smsButtonStyle = {
		marginTop: "45px",
		borderRadius: "16px",
		color: "white",
	};

	const validatePhoneNumber = (phoneNumber, onSuccess, onError) =>
		schema
			.validate({ [FIELD_SMS_PHONE_NUMBER]: phoneNumber })
			.then((result) => {
				const isDuplicate = phoneNumbers.some(
					(pn) => pn.phoneNumber === phoneNumber
				);
				if (isDuplicate) {
					return onError(new Error("Phone number is already a recipient"));
				}
				onSuccess(result);
			})
			.catch(onError);

	const handlePhoneNumberChange = (event) => {
		setPhoneNumber(event.target.value);
		return validatePhoneNumber(
			event.target.value,
			() => {
				setSmsValidationError(null);
			},
			(error) => setSmsValidationError(error.message)
		);
	};

	const setBeingEdited = (editIdx, beingEdited) => () =>
		setPhoneNumbers(
			phoneNumbers.map(({ phoneNumber }, idx) =>
				editIdx === idx
					? { phoneNumber, beingEdited }
					: { phoneNumber, beingEdited: false }
			)
		);

	const handleDelete = (deleteIdx) => () =>
		setPhoneNumbers(phoneNumbers.filter((_, idx) => deleteIdx !== idx));

	const handleAddNumber = (event) => {
		event.preventDefault();
		const isDuplicate = phoneNumbers.some(
			(pn) => pn.phoneNumber === phoneNumber
		);
		if (isDuplicate) {
			setSmsValidationError("Phone number is already a recipient");
			return;
		}
		setPhoneNumbers([...phoneNumbers, { phoneNumber, beingEdited: false }]);
		setPhoneNumber("+254");
	};

	const handleSendSms = () => {
		setValidPhoneNumbers(removeInvalidPhoneNumbers(phoneNumbers));
		setDialogOpen(true);
	};

	return (
		<Page crumbs={[{ path: "/sendSms", title: "Send SMS" }]}>
			<Title title="Send SMS to County Contacts" />
			<div className={classes.textFieldContainer}>
				<div className={classes.inputFieldContainer}>
					<TextField
						label="SMS Text"
						multiline
						rows={6}
						id="input-field-sms"
						placeholder="142 characters/SMS"
						value={smsMessage}
						color="secondary"
						variant="outlined"
						helperText={count > 0 && count + " SMS"}
						onChange={({ target }) => setSmsText(target.value)}
					/>
					<Button
						disabled={smsMessage.length === 0 || Boolean(smsValidationError)}
						variant="contained"
						style={smsButtonStyle}
						color="secondary"
						onClick={handleSendSms}
						data-testid="submit-sms-button"
					>
            Send SMS
					</Button>
				</div>
				<div className={classes.inputFieldContainer}>
					<form className={classes.formContainer} autoComplete="off">
						<TextField
							label="Send to:"
							error={Boolean(smsValidationError)}
							value={phoneNumber}
							id="send-input-field"
							onChange={handlePhoneNumberChange}
							helperText={smsValidationError}
						/>

						{phoneNumbers.length > 0 && (
							<PhoneNumbers
								smsPhoneNumbers={phoneNumbers}
								edit={setBeingEdited}
								remove={handleDelete}
								setPhoneNumbers={setPhoneNumbers}
								validatePhoneNumber={validatePhoneNumber}
							/>
						)}

						<Button
							disabled={
								phoneNumber.length < validPhoneNumberLength ||
                Boolean(smsValidationError)
							}
							type="submit"
							variant="contained"
							color="secondary"
							style={smsButtonStyle}
							startIcon={<AddIcon />}
							onClick={handleAddNumber}
							data-testid="add-number-button"
						>
              Add Number
						</Button>
					</form>
				</div>
				<ConfirmationMessage
					onClose={() => setDialogOpen(false)}
					aria-labelledby="send-confirmation-popup"
					open={isDialogOpened}
					phoneNumbers={validPhoneNumbers}
					smsMessage={smsMessage}
					setSmsText={setSmsText}
				/>
			</div>
		</Page>
	);
};
