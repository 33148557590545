import Page from "../../components/layout/page/Page";
import React from "react";
import classes from "./login.module.scss";
import GoogleButton from "../../components/auth/GoogleButton";

const Login = () => {
	const loginWithGoogle = () => {
		window.location.href = process.env.REACT_APP_LOGIN_URL;
	};
	return (
		<Page>
			<div className={classes.login}>
				<h1 className={classes.title}>
          Customer Support
					<br />
          Clinic Connect - Facilities
				</h1>
				<GoogleButton
					onClick={loginWithGoogle}
					className={classes.loginImage}
					data-testid="login-google-button"
				/>
				<p className={classes.direction}>
          Please sign in with Google using your Elephant email
				</p>
				<div style={{ fontSize: "10px", color: "#ccc" }}>
          App version 1.0.14
				</div>
			</div>
		</Page>
	);
};

export default Login;
