import React, { forwardRef } from "react";
import { headerStyle, rowStyle } from "./tableStyles";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Chip from "@material-ui/core/Chip";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import { Link } from "react-router-dom";
import { Paper } from "@material-ui/core";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import classes from "./facilityTable.module.scss";

export const FACILITIES_TABLE_ICONS = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const FACILITIES_TABLE_OPTIONS = {
	headerStyle,
	rowStyle,
	showTitle: false,
	pageSize: 6,
	pageSizeOptions: [6, 12, 20],
	emptyRowsWhenPaging: false,
};

export const FACILITIES_TABLE_COMPONENTS = {
	Container: (props) => <Paper {...props} elevation={0} />,
};

const chipStyles = {
	color: "white",
	fontWeight: "bold",
	height: "22px",
	borderRadius: "14px",
};

const renderInChargeMembersName = (rowData) => {
	let count = rowData.inChargeMembers.length;
	switch (count) {
	case 0:
		return "Unknown";
	case 1:
		return rowData.inChargeMembers[0].name;
	default:
		return rowData.inChargeMembers[0].name + ` (+${count - 1}\xa0more)`;
	}
};

export const FACILITIES_TABLE_COLUMNS = [
	{
		title: "Facility",
		field: "facilityName",
		render: (rowData) => {
			return (
				<Link to={`/facility/${rowData.facilityId}`} className={classes.Link}>
					{rowData.facilityName}
				</Link>
			);
		},
	},
	{ title: "Ward", field: "subDivision2Name" },
	{ title: "Sub-county", field: "subDivision1Name" },
	{ title: "County", field: "organisationName" },
	// assuming that the 'primary' in-charge is always the first person in the array of in-charges
	{
		title: "In-charge",
		field: "inChargeMembers[0].name",
		customSort: (a, b) =>
			renderInChargeMembersName(a).toLowerCase() >
      renderInChargeMembersName(b).toLowerCase()
				? -1
				: 1,
		render: renderInChargeMembersName,
	},
	{
		title: "Number",
		field: "inChargeMembers",
		render: (rowData) => {
			if (
				rowData &&
        rowData.inChargeMembers &&
        rowData.inChargeMembers.length > 0
			) {
				return rowData.inChargeMembers[0].mobileNumber;
			}
			return "Unknown";
		},
	},
	{
		title: "Registration",
		field: "dateRegistered",
		render: (rowData) => {
			let label = "Not registered";
			if (rowData.dateRegistered) {
				label = new Date(rowData.dateRegistered).toLocaleDateString();
			}
			return (
				<Chip
					label={label}
					style={
						rowData.dateRegistered
							? { ...chipStyles, backgroundColor: "#459EAD" }
							: { ...chipStyles, backgroundColor: "#D23201" }
					}
				/>
			);
		},
	},
];
