import React, { useEffect } from "react";

import { useAuth } from "../../providers/AuthProvider";

const Logout = ({ history }) => {
	const { logout } = useAuth();
	useEffect(() => {
		logout();
		history.push("/login");
	}, [logout, history]);
	return <div style={{ margin: 10 }}>Loading...</div>;
};

export default Logout;
