import {
	FIELD_INCHARGE_FULLNAME,
	FIELD_INCHARGE_MOBILE_NUMBER,
} from "../constants";
import { object, string } from "yup";

import { validateKenyanNumber } from "../utils";

export const generateSchema = () => {
	return object().shape({
		[FIELD_INCHARGE_FULLNAME]: string().min(4).required(),
		[FIELD_INCHARGE_MOBILE_NUMBER]: validateKenyanNumber,
	});
};

export const fieldValidation = {
	[FIELD_INCHARGE_MOBILE_NUMBER]: () => validateKenyanNumber,
	[FIELD_INCHARGE_FULLNAME]: () => string().min(4).required(),
};
