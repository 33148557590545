import { arrayOf, shape, string } from "prop-types";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";
import React from "react";
import classes from "./breadCrumbs.module.scss";
import clsx from "clsx";
import { intersperse } from "../../../utils/utils";

const Breadcrumbs = ({ crumbs }) => (
	<div className={classes.breadCrumbContainer}>
		{intersperse(crumbs.map(BreadCrumb(crumbs.length)), Separator)}
	</div>
);

Breadcrumbs.propTypes = {
	crumbs: arrayOf(
		shape({
			title: string,
			path: string,
		})
	),
};

const BreadCrumb = (numberOfCrumbs) => ({ title, path }, idx) => (
	<Link
		key={path}
		to={path}
		className={
			numberOfCrumbs === idx + 1
				? clsx(classes.link, classes.breadCrumb, classes.active)
				: clsx(classes.link, classes.breadCrumb, classes.inactive)
		}
	>
		{title}
	</Link>
);

const Separator = (
	<ChevronRightIcon className={classes.ChevronRightIcon} key="separator" />
);

export default Breadcrumbs;
